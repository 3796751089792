<template>
<p>กำลังเข้าสู่ระบบ...</p>
</template>

<script>
import {GRAPH} from "@/axios.js"
import {HTTP} from "@/axios.js"
import apiExamManager from '../../services/exam-manager-api-services'
import Auth from "@/services/Auth"

export default {
  name: 'Callback',
  data(){
    return {
      adminList:['nuttapong.pongkam@cmu.ac.th','thapakorn.pe@cmu.ac.th']
    }
  },
  created() {
      if(this.$route.query.code){
        localStorage.setItem('code', this.$route.query.code)
        this.filterUser(this.$route.query.code)
      }
  },
  methods: {
    async filterUser(queryCode){
      let access_token = await Auth.getTokenFromBe(process.env.VUE_APP_REDIRECT_URI, queryCode)
      if(access_token.status !== "fail" && access_token !== "error something"){
        access_token.data[0] = JSON.parse(access_token.data[0])
        let user = await Auth.getUserInGraph(access_token.data[0].access_token)
        let userProfile = await apiExamManager.getStudentProfile(user.mail)
        if(userProfile['status']){
          console.log("userProfile['data']:" , userProfile['data'])
          localStorage.setItem('userProfile', JSON.stringify(userProfile['data']))
        }
        if(!(user instanceof Error)){
          localStorage.setItem('access_token', access_token.data[0].access_token)
          if(user){
            if(this.adminList.includes(user.mail)){
              localStorage.setItem('Admin', 'admin')
            }else{
              localStorage.setItem('Admin', 'user')
            }
            localStorage.setItem('email', user.mail)
            localStorage.setItem('name', user.displayName)
            localStorage.setItem('AccType', user.jobTitle)
            this.$router.push({path: `student/code/${localStorage.getItem('stdEventId') ? localStorage.getItem('stdEventId') : ''}`})
          }else{
            localStorage.removeItem('access_token')
            localStorage.removeItem('email')
            localStorage.removeItem('name')
            localStorage.removeItem('AccType')
            localStorage.removeItem('code')
            localStorage.removeItem('userProfile')
            localStorage.removeItem('Admin')
            this.$router.push({path: '/pages/500'})
          }
        }
      }else{
        this.$router.push({path: '/pages/500'})
      }
    },
    async getStudentReportEachUser(email){
      var response
      await HTTP.get(`api/event/leave/user?eventid=${this.eventID}&email=${email}`)
      .then(res => {
        if (res.data) {
          let resData = JSON.parse(JSON.stringify(res.data))
          console.log("getStudentReportEachUser(API):",resData);
          response = resData
        }
      })
      .catch(e => {
        console.log(e);
      });
      return response
    },
  }
}
</script>
